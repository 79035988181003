import { googleLogout } from "@react-oauth/google";
import React, { useContext } from "react";
import { useCookies } from "react-cookie";
import { Link, useLocation } from "react-router-dom";

import AuthContext from "../../context/Auth/AuthContext";
import { getSettingssRoutes } from "../../handlers/navigationItems";
import Icon from "../Icon";

function ExpertSidebar({ navItems, screenWidth, screenHeight, showSideBar, setShowSideBar }) {
	const location = useLocation();

	const { setUserData } = useContext(AuthContext);

	const [__, _, removeCookie] = useCookies(["Authorization"]);

	return (
		<>
			{(screenWidth > 768 || showSideBar) && (
				<div
					style={{
						...(screenWidth <= 768 && {
							position: "absolute",
							top: 0,
							right: 0,
							left: 0,
							bottom: 0,
							background: "rgba(0,0,0,0.4)",
							zIndex: 999,
						}),
					}}
					onClick={() => {
						setShowSideBar(false);
					}}
				>
					<div
						className="expert-review-sidebar-container"
						style={{
							height: `calc(${screenHeight}px - 24px)`,
							...(screenWidth <= 768 && { height: `${screenHeight}px` }),
						}}
						onClick={(event) => {
							event.stopPropagation();
							setShowSideBar(true);
						}}
					>
						<div className="expert-review-navitems-container">
							{getSettingssRoutes().some((item) => item?.route === location.pathname) ? (
								<Link to="/my-resumes">
									<div className="flex items-center cursor-pointer">
										<i className="bi bi-chevron-left mr-3" />
										<span className="text-lg font-semibold text-gray-500">Settings</span>
									</div>
								</Link>
							) : (
								<Link to="/" style={{ marginLeft: "22px" }}>
									<img src="/images/logo.png" alt="Logo" className="navbar-brand-img" />
								</Link>
							)}

							{navItems &&
								navItems.map((item, index) => {
									if (item.type === "group") {
										return (
											<div key={index} className="font-medium pl-3 pt-4 text-sm">
												{item.name}
											</div>
										);
									}

									return (
										<Link
											key={index}
											to={item.route}
											onClick={() => {
												item.onClick && item.onClick();
												screenWidth <= 768 && setShowSideBar(false);
											}}
										>
											<div
												className="expert-review-navitem"
												style={{
													...(location.pathname === item.route && { background: "#DFEEFF", borderRadius: "8px" }),
												}}
											>
												{item?.icon && <Icon iconName={item?.icon} size={item?.iconSize} />}
												<div
													className="expert-review-navitem-text text-sm"
													style={{
														...(location.pathname === item.route && { color: "#0069CB" }),
													}}
												>
													{item.name}
												</div>
											</div>
										</Link>
									);
								})}
						</div>
						<div className="expert-review-footer">
							<div className="expert-review-footer-text">Need help?</div>
							<div className="expert-review-footer-text">
								Contact our{" "}
								<span className="cursor-pointer" style={{ color: "#34A3FC", fontWeight: 500 }}>
									support team
								</span>
							</div>
							<button
								onClick={() => {
									googleLogout();
									removeCookie("Authorization");
									localStorage.removeItem("profileData");
									setUserData(null);
									window.location.href = "/signin";
								}}
							>
								<div className="flex items-center text-blue-500 pt-3">
									<i className="bi bi-box-arrow-right pr-3" />
									Logout
								</div>
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default ExpertSidebar;
