import React, { useRef } from "react";

import { cn } from "../../handlers/cn.utils";
import Modal from "./Modal";

function ColumnItem({ setColumns, column }) {
	const inputRef = useRef(null);

	return (
		<div className="mb-2 border-b border-gray-100">
			<label
				className="flex w-full cursor-pointer items-center justify-between py-2"
				onChange={() =>
					setColumns((prev) =>
						prev.map((item) => {
							if (item.key === column.key) {
								return { ...item, visible: !item.visible };
							}
							return item;
						}),
					)
				}
			>
				<input type="checkbox" checked={column.visible} ref={inputRef} className="peer sr-only" />
				<span className="text-base">{column.label}</span>
				<div
					className={cn(
						"peer relative h-5 w-9 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-4 after:w-4 after:rounded-full after:bg-gray-50 after:transition-all after:content-[''] peer-checked:bg-blue-800 peer-checked:after:translate-x-full peer-focus:outline-none rtl:peer-checked:after:-translate-x-full",
					)}
				/>
			</label>
		</div>
	);
}

function EditColumnsModal({
	allColumns,
	columns,
	setColumns,
	showEditColumnModal,
	setShowEditColumnModal,
	setPageSize,
	pageSize,
	storageKey,
}) {
	const mergedColumns = allColumns.map((column) => {
		const found = columns.find((item) => item.key === column.key);
		if (found) {
			return found;
		}
		return column;
	});

	const save = () => {
		localStorage.setItem(storageKey, JSON.stringify({ columns, pageSize }));
		setShowEditColumnModal(false);
	};

	return (
		<Modal isOpen={showEditColumnModal} onClose={save} title="Preferences">
			<div className="p-3">
				<div className="grid grid-cols-2">
					<div className="border-r border-gray-200">
						<p className="mb-2 text-base text-gray-500">Page Size</p>
						{[10, 20, 50, 100].map((size) => (
							<div key={size} className="flex items-center gap-1 py-1">
								<input type="radio" name="pageSize" checked={pageSize === size} onChange={() => setPageSize(size)} />
								<span>{size}</span>
							</div>
						))}
					</div>
					<div className="pl-6">
						<span className="mb-2 text-base text-gray-500">Select visible columns</span>
						{mergedColumns.map((column) => (
							<ColumnItem key={column.key} setColumns={setColumns} column={column} />
						))}
					</div>
				</div>

				<div className="flex justify-end gap-2">
					<button
						className="mt-3 flex gap-2 rounded-lg bg-primary px-3 py-2 text-base text-white hover:bg-primary-hover"
						onClick={save}
					>
						Save
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default EditColumnsModal;
