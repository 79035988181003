import React from "react";
import toast from "react-hot-toast";

import { cn } from "../../../handlers/cn.utils";
import mapping from "../../../seed/metadata/filemapping";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function Search({
	setJobs,
	setLoading,
	searchedAddress,
	// jobSearchSource,
	selectedCategory,
	searchedJobTitle,
	setSelectedCategory,
}) {
	const apiService = new ResumeDataService();
	const addresses = mapping["districtsRegions"][0].districtRegions;
	const [categories, setCategories] = React.useState([]);
	const [showAddresses, setShowAddresses] = React.useState(false);
	const [addressLocation, setAddressLocation] = React.useState(searchedAddress || "");
	const filteredAddresses = addresses.filter((option) => option.toLowerCase().includes(addressLocation.toLowerCase()));

	async function fetchCategories() {
		await apiService.getJobCategories(
			({ data }) => {
				setLoading(false);
				setCategories(data);
			},
			(error) => {
				setLoading(false);
				toast.error(error.message);
			},
		);
	}

	const fetchJobs = () => {
		setLoading(true);
		const address = addressLocation.split(",");
		const searchQueryParams = {
			title: searchedJobTitle || "",
			country: "Tanzania",
			region: address?.[1] || "",
			city: address?.[0] || "",
			// source: jobSearchSource || "",
			jobExpertise: [selectedCategory].filter((category) => category.toLowerCase() !== "all"),
			pageNumber: 1,
		};
		apiService.getSearchedJobs(searchQueryParams, successCallBack, errorCallBack);
	};

	const successCallBack = (response) => {
		setJobs(response?.data?.[0]?.paginatedJobs || []);
		setLoading(false);
	};

	const errorCallBack = () => {
		setLoading(false);
		toast.error("Something went wrong");
	};

	React.useEffect(() => {
		fetchCategories();
	}, []);

	React.useEffect(() => {
		if (searchedJobTitle !== undefined || searchedAddress !== undefined) {
			fetchJobs();
		}
	}, []);

	return (
		<div className="!rounded-md border border-gray-200 bg-white mb-8 shadow-md">
			<div className="p-6">
				<div className="flex flex-col sm:flex-row gap-4">
					<div className="relative w-full sm:w-[180px]">
						<label htmlFor="category-select" className="block text-sm font-semibold text-gray-800 mb-1">
							Category
						</label>

						<select
							aria-hidden="true"
							className="flex h-10 rounded-sm border p-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full border-gray-300 bg-white text-gray-800 placeholder-gray-500 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
							onChange={(e) => setSelectedCategory(e.target.value)}
						>
							<option value="all" selected="">
								All
							</option>
							{categories.map((category, index) => (
								<option value={category._id} key={index}>
									{category._id}
								</option>
							))}
						</select>
					</div>
					<div className="flex-grow">
						<div className="relative flex-grow">
							<label htmlFor="location-input" className="block text-sm font-semibold text-gray-800 mb-1">
								Location
							</label>
							<input
								value={addressLocation}
								className="flex h-10 rounded-sm border p-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full border-gray-300 bg-white text-gray-800 placeholder-gray-500 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
								id="location-input"
								placeholder="e.g., Tanzania"
								onChange={(e) => setAddressLocation(e.target.value)}
								onFocus={() => {
									setShowAddresses(true);
								}}
								onBlur={() => {
									setTimeout(() => {
										setShowAddresses(false);
									}, 100);
								}}
							/>
						</div>
						{showAddresses && (
							<ul className="bg-white max-h-48 overflow-y-auto">
								{filteredAddresses.length > 0 ? (
									filteredAddresses.map((address, index) => (
										<li
											key={index}
											className={cn("cursor-pointer p-2 text-left hover:bg-gray-200", {
												"bg-gray-100": addressLocation === address,
											})}
											onClick={() => {
												setAddressLocation(address);
											}}
										>
											{address}
										</li>
									))
								) : (
									<li className="p-2 text-gray-500">No results found</li>
								)}
							</ul>
						)}
					</div>
					<div className="mt-4">
						<button
							className="btn btn-lg bg-blue-200 text-blue-800"
							disabled={!(selectedCategory !== "" || addressLocation !== "")}
							onClick={() => fetchJobs()}
						>
							Search Jobs
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Search;
