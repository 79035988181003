import React from "react";
import { Modal } from "react-bootstrap";

import Accordion from "../../../components/Accordion";
import SearchableSelectInput from "../../../components/SearchableSelectInput/SearchableSelectInput";
import TextEditor from "../../../components/TextEditor";

function CreateModal({
	showCreateCompanyDialog,
	handleCloseCreateCompanyDialog,
	setShowCreateCompanyDialog,
	errors,
	setCompanyDetails,
	companyDetails,
	submitPhase,
	companyTypes,
	companyIndustries,
	aboutUs,
	setAboutUs,
	setErrors,
	onSubmit,
}) {
	const handleOnChange = (e) => {
		const { name, value } = e.target;
		const _companyDetails = { ...companyDetails };

		_companyDetails[name] = value;
		setCompanyDetails(_companyDetails);
	};

	const handleOnSelect = (value, name) => {
		handleOnChange({ target: { value, name } });
	};

	return (
		<Modal show={showCreateCompanyDialog} onHide={handleCloseCreateCompanyDialog} centered size="xl" backdrop="static">
			<Modal.Header>
				<Modal.Title>Create Company</Modal.Title>
				<button
					type="button"
					className="btn btn-secondary text-center"
					data-dismiss="modal"
					aria-label="Close"
					onClick={() => setShowCreateCompanyDialog(false)}
				>
					<i className="fa fa-close ml-2" />
				</button>
			</Modal.Header>
			<Modal.Body>
				<div>
					{errors.length > 0 && (
						<div className="alert alert-danger" role="alert">
							{errors}
						</div>
					)}
					<div className="form-group">
						<div className="mb-3">
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignContent: "flex-end",
								}}
							>
								<div style={{ width: "100%" }}>
									<label htmlFor="company_name" className="form-label">
										Company Name
									</label>

									<input
										type="text"
										className="form-control"
										onChange={handleOnChange}
										value={companyDetails.company_name}
										name="company_name"
										id="company_name"
									/>
									{errors.company_name && errors.company_name !== "" && (
										<div className="text-danger m-0">{errors.company_name}</div>
									)}
								</div>
								<div className="input-cross-tick-container">
									<i
										className={`fa text-input-cross-tick ${
											(submitPhase && !(errors.company_name && errors.company_name !== "")) ||
											(companyDetails.company_name && "fa-check text-success")
										}`}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="mb-3  col-4">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="industry" className="form-label">
											Company Industry
										</label>

										<SearchableSelectInput
											defaultLabel="Search Company Industries"
											value={companyDetails.company_industry}
											options={companyIndustries.map((item) => {
												return { label: item, value: item };
											})}
											name="company_industry"
											onSelect={(value) => handleOnSelect(value, "company_industry")}
										/>

										{errors.company_industry && errors.company_industry !== "" && (
											<div className="text-danger m-0">{errors.company_industry}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.company_industry && errors.company_industry !== "")) ||
												(companyDetails.company_industry && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3 col-4">
								<label htmlFor="company_size_approx" className="form-label">
									Company Size
								</label>
								<select
									className="form-select form-control"
									onChange={handleOnChange}
									value={companyDetails.company_size_approx}
									name="company_size_approx"
									id="company_size_approx"
								>
									<option value="">Select Company Size</option>
									<option value="1-10">1 - 10</option>
									<option value="11-50">11 - 50</option>
									<option value="51-100">51 - 100</option>
									<option value="101-500">101 - 500</option>
									<option value="above-500">above 500</option>
								</select>
								{errors.company_size_approx && errors.company_size_approx !== "" && (
									<div className="text-danger m-0">{errors.company_size_approx}</div>
								)}
							</div>

							<div className="mb-3  col-4">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="headquarters" className="form-label">
											HeadQuarters
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.headquarters}
											name="headquarters"
											id="headquarters"
										/>
										{errors.headquarters && errors.headquarters !== "" && (
											<div className="text-danger m-0">{errors.headquarters}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.headquarters && errors.headquarters !== "")) ||
												(companyDetails.headquarters && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-4">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="locations" className="form-label">
											Locations
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.locations}
											name="locations"
											id="locations"
										/>
										{errors.locations && errors.locations !== "" && (
											<div className="text-danger m-0">{errors.locations}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.locations && errors.locations !== "")) ||
												(companyDetails.locations && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="type" className="form-label">
											Type
										</label>

										<SearchableSelectInput
											defaultLabel="Search Company Type"
											value={companyDetails.type}
											options={companyTypes.map((item) => {
												return { label: item, value: item };
											})}
											onSelect={(value) => handleOnSelect(value, "type")}
										/>

										{errors.type && errors.type !== "" && <div className="text-danger m-0">{errors.type}</div>}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.type && errors.type !== "")) ||
												(companyDetails.type && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="specialties" className="form-label">
											Specialties
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.specialties}
											name="specialties"
											id="specialties"
										/>
										{errors.specialties && errors.specialties !== "" && (
											<div className="text-danger m-0">{errors.specialties}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.specialties && errors.specialties !== "")) ||
												(companyDetails.specialties && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="funding" className="form-label">
											Funding
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.funding}
											name="funding"
											id="funding"
										/>
										{errors.funding && errors.funding !== "" && <div className="text-danger m-0">{errors.funding}</div>}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.funding && errors.funding !== "")) ||
												(companyDetails.funding && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="funding_total_rounds" className="form-label">
											funding_total_rounds Total Rounds
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.funding_total_rounds}
											name="funding_total_rounds"
											id="funding_total_rounds"
										/>
										{errors.funding_total_rounds && errors.funding_total_rounds !== "" && (
											<div className="text-danger m-0">{errors.funding_total_rounds}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.funding_total_rounds && errors.funding_total_rounds !== "")) ||
												(companyDetails.funding_total_rounds && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="funding_option" className="form-label">
											Funding Option
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.funding_option}
											name="funding_option"
											id="funding_option"
										/>
										{errors.funding_option && errors.funding_option !== "" && (
											<div className="text-danger m-0">{errors.funding_option}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.funding_option && errors.funding_option !== "")) ||
												(companyDetails.funding_option && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="last_funding_round" className="form-label">
											Last Funding Round
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.last_funding_round}
											name="last_funding_round"
											id="last_funding_round"
										/>
										{errors.last_funding_round && errors.last_funding_round !== "" && (
											<div className="text-danger m-0">{errors.last_funding_round}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.last_funding_round && errors.last_funding_round !== "")) ||
												(companyDetails.last_funding_round && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="website" className="form-label">
											Website
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.website}
											name="website"
											id="website"
										/>
										{errors.website && errors.website !== "" && <div className="text-danger m-0">{errors.website}</div>}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.website && errors.website !== "")) ||
												(companyDetails.website && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="logo_url" className="form-label">
											Company Logo
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.logo_url}
											name="logo_url"
											id="logo_url"
										/>
										{errors.logo_url && errors.logo_url !== "" && (
											<div className="text-danger m-0">{errors.logo_url}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.logo_url && errors.logo_url !== "")) ||
												(companyDetails.logo_url && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="linkedin_url" className="form-label">
											Linkedin url
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.linkedin_url}
											name="linkedin_url"
											id="linkedin_url"
										/>
										{errors.linkedin_url && errors.linkedin_url !== "" && (
											<div className="text-danger m-0">{errors.linkedin_url}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.linkedin_url && errors.linkedin_url !== "")) ||
												(companyDetails.linkedin_url && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="linkedin_followers_count" className="form-label">
											Linkedin followers count
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.linkedin_followers_count}
											name="linkedin_followers_count"
											id="linkedin_followers_count"
										/>
										{errors.linkedin_followers_count && errors.linkedin_followers_count !== "" && (
											<div className="text-danger m-0">{errors.linkedin_followers_count}</div>
										)}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.linkedin_followers_count && errors.linkedin_followers_count !== "")) ||
												(companyDetails.linkedin_followers_count && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>

							<div className="mb-3  col-4">
								{" "}
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignContent: "flex-end",
									}}
								>
									<div style={{ width: "100%" }}>
										<label htmlFor="founded" className="form-label">
											Founded
										</label>

										<input
											type="text"
											className="form-control"
											onChange={handleOnChange}
											value={companyDetails.founded}
											name="founded"
											id="founded"
										/>
										{errors.founded && errors.founded !== "" && <div className="text-danger m-0">{errors.founded}</div>}
									</div>
									<div className="input-cross-tick-container">
										<i
											className={`fa text-input-cross-tick ${
												(submitPhase && !(errors.founded && errors.founded !== "")) ||
												(companyDetails.founded && "fa-check text-success")
											}`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="mb-3">
							<Accordion label="Company Description">
								<TextEditor
									value={aboutUs}
									id="about_us"
									name="about_us"
									setValue={setAboutUs}
									placeholder="Add Company Description here..."
								/>
								{errors.about_us && errors.about_us !== "" && <div className="text-danger m-0">{errors.about_us}</div>}
							</Accordion>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="d-flex justify-content-end">
					<button
						type="button"
						className="btn btn-secondary mx-1"
						data-dismiss="modal"
						onClick={() => {
							setShowCreateCompanyDialog(false);
							setErrors([]);
						}}
					>
						Cancel
					</button>

					<button
						type="button"
						className="btn btn-primary mx-1"
						disabled={
							!(
								errors.company_name === "" &&
								errors.company_industry === "" &&
								errors.about_us === "" &&
								errors.company_size_approx === "" &&
								errors.locations === "" &&
								errors.website === ""
							)
						}
						onClick={onSubmit}
					>
						ADD
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}

export default CreateModal;
