import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../../components/Loader";
import EllipsisPagination from "../../../components/UIComponents/EllipsisPagination";
import { getJobsRoutes } from "../../../handlers/navigationItems";
import { ResumeDataService } from "../../../services/create-resume/resume-data";

function JobDescriptions() {
	const navigate = useNavigate();
	const apiService = new ResumeDataService();

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState("");
	const [jobDescriptions, setJobDescriptions] = useState([]);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const totalPages = Math.ceil(jobDescriptions.length / rowsPerPage);
	const [page, setPage] = React.useState(1);
	const [searchTerm, setSearchTerm] = React.useState("");
	const handleFilter = (item) =>
		Object.values(item).some((value) => value?.toString()?.toLowerCase()?.includes(searchTerm?.toLowerCase()));

	const handlePageChange = (pageNumber) => {
		setPage(pageNumber);
	};

	useEffect(() => {
		apiService.getJobDescriptions(
			(data) => {
				setLoading(false);
				setJobDescriptions(data.data);
			},
			(e) => {
				setLoading(false);
				const error = e.response.data;
				setErrors(error);
				toast.error("Something went wrong!");
			},
		);
	}, []);
	return (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Job Descriptions" headingIcon="arrow-left">
			<div className="my-resumes-section">
				<div className="container">
					{loading ? (
						<Loader />
					) : (
						<>
							{errors && (
								<div className="alert alert-danger" role="alert">
									{errors}
								</div>
							)}
							<div className="d-flex mb-3 border py-2 rounded">
								<i className="fas fa-search my-auto p-3" />
								<div className="form-group bg-white my-auto">
									<input
										type="text"
										className="form-control"
										id="search"
										autoComplete="off"
										placeholder="Type to Filter"
										value={searchTerm}
										onChange={(event) => {
											setSearchTerm(event.target.value);
										}}
										style={{
											width: "calc(100vw - 170px)",
											maxWidth: "410px",
											borderColor: "#e8e6df",
										}}
									/>
								</div>
							</div>
							{jobDescriptions
								.filter(handleFilter)
								.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
								.map((jobDescription) => (
									<div className="row mt-3" key={jobDescription._id}>
										<div className="col-lg-12">
											<div
												onClick={(event) => {
													event.stopPropagation();
													navigate(`/job-market/jds/${jobDescription._id}`);
												}}
												className="w-100 no-link card card-body mb-3 p-4 rounded-sm cursor-pointer"
											>
												<div className="row">
													<div className="col-md-8 row no-gutters pr-3">
														<div className="col-1 d-flex justify-content-center align-items-center">
															<span className="border job-circle p-3 bg-primary text-white">
																<i className="fas fa-briefcase" />
															</span>
														</div>
														<div className="col-11 pl-4">
															<div className="row mb-1 pl-3 gap-0">
																<h5 className="m-0 mr-2">{jobDescription.title}</h5>
																<p className="col-md-7 p-0 m-0 h5">{jobDescription.company}</p>
															</div>
															<p className="m-0">
																Added at: {dayjs(jobDescription.addedAt).format("DD MMM YYYY, h:mm A")}
															</p>
															<p className="m-0">
																Reviewed on: {dayjs(jobDescription.lastReviewedOn).format("DD MMM YYYY, h:mm A")}
															</p>
															<p className="m-0">
																Point Of Contact: {jobDescription.hiringPointOfContact.firstName.toUpperCase()}{" "}
																{jobDescription.hiringPointOfContact.lastName.toUpperCase()},{" "}
																{jobDescription.hiringPointOfContact.emailAddress}
															</p>
														</div>
													</div>
													<div className="col-md-4">
														<p className="m-0 text-primary h5 my-1">
															{jobDescription.screeningMode.replaceAll("_", " ")}
														</p>
														{jobDescription.reviewStatus !== "APPROVED" && (
															<>
																<p
																	className={
																		jobDescription.reviewStatus === "REJECTED" ? "text-danger" : "text-warning"
																	}
																>
																	{jobDescription.reviewStatus.replaceAll("_", " ")}
																</p>
																{jobDescription.reviewStatus === "REJECTED" && (
																	<p>
																		<strong>Reason:</strong> {jobDescription.reviewDetails}
																	</p>
																)}
															</>
														)}
														{jobDescription.reviewStatus === "APPROVED" && (
															<Link
																onClick={(event) => {
																	event.stopPropagation();
																}}
																to={`/job-market/post?jdID=${jobDescription._id}`}
																className="btn btn-primary my-1 mr-2"
															>
																Post Job
															</Link>
														)}
														<button
															className="btn btn-sm btn-primary my-1 fs-12"
															onClick={(event) => {
																event.stopPropagation();
																navigate("/job-market/companies", {
																	state: { companyName: jobDescription.company },
																});
															}}
														>
															<span className="fas fa-plus mr-2" />
															Add {jobDescription.company}
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							<EllipsisPagination
								totalPages={totalPages}
								currentPage={page}
								onPageChange={handlePageChange}
								rowsPerPage={rowsPerPage}
								setRowsPerPage={setRowsPerPage}
								activePage={page}
								length={jobDescriptions.length}
								dataName="Job Descriptions"
							/>
						</>
					)}
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default JobDescriptions;
