import React from "react";

import { cn } from "../../../handlers/cn.utils";

function Categories({ categories, setSelectedCategory, selectedCategory }) {
	return (
		<div className="bg-white !rounded-md shadow-md border border-gray-200 overflow-hidden">
			<div className="px-4 py-3 bg-gray-50 border-b border-gray-200">
				<h3 className="text-lg font-semibold text-gray-800 font-plus-jakarta">Explore Jobs by Category</h3>
			</div>
			<div className="p-2">
				<ul className="space-y-1">
					{categories.map((category) => (
						<li key={category} onClick={() => setSelectedCategory(category)}>
							<button
								className={cn(
									"w-full text-left px-3 py-2 rounded-sm transition-colors duration-200 flex items-center text-gray-700 hover:bg-gray-100",
									{
										"!bg-blue-100 text-blue-700": selectedCategory === category,
									},
								)}
							>
								<img
									alt="Technology icon"
									loading="lazy"
									width="18"
									height="18"
									decoding="async"
									data-nimg="1"
									className="mr-2 opacity-75"
									src="https://picsum.photos/20"
								/>
								<span className="text-sm font-medium">{category}</span>
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Categories;
