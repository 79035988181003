import React, { useMemo } from "react";

function UserAvatar({ name }) {
	const initials = useMemo(() => {
		const [firstName, lastName] = name.split(" ");
		return `${firstName[0]}${lastName[0]}`;
	}, [name]);
	return (
		<div className="flex w-fit items-center rounded-full border border-gray-100 p-1 text-xs">
			<div className="mr-1 grid h-5 w-5 place-items-center rounded-full bg-gray-100 font-medium leading-none text-gray-700">
				{initials}
			</div>
			<div className="mr-1 text-gray-950">{name}</div>
		</div>
	);
}

export default UserAvatar;
