import React from "react";

function DropdownSearch({ value, onChange, ...props }) {
	return (
		<div>
			<input
				type="text"
				value={value}
				onChange={onChange}
				placeholder="Search"
				className="mb-2 w-full rounded-md border-gray-100 px-2 py-1 text-sm shadow-sm"
				{...props}
			/>
		</div>
	);
}

export default DropdownSearch;
