import dayjs from "dayjs";
import React, { useState } from "react";

import ViewJob from "../../StaticPages/HomePage/components/ViewJob";

function Jobs({ jobs }) {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<>
			{showJobDetailsModal && (
				<ViewJob data={selectedJob} setShowModal={setShowJobDetailsModal} setData={setSelectedJob} />
			)}
			{jobs.map((company, index) => (
				<div className="mb-6" key={index}>
					<div className="rounded border border-gray-200 bg-white shadow-sm overflow-hidden">
						<div className="p-5">
							<div className="flex items-center mb-3 cursor-pointer">
								<img
									alt="EduTech Solutions logo"
									loading="lazy"
									width="64"
									height="64"
									decoding="async"
									data-nimg="1"
									className="rounded mr-4 hover:opacity-80 transition-opacity duration-200"
									src={
										company?.companyDetails
											? company?.companyDetails?.logo_url
											: "https://picsum.photos/200?random=" + index
									}
								/>
								<div>
									<h3 className="text-xl font-bold text-gray-900 font-plus-jakarta hover:text-gray-700 transition-colors duration-200">
										{company?.companyDetails?.company_name}
									</h3>
									<p className="text-sm text-gray-700 font-inter mt-1">
										{company?.companyDetails ? company?.companyDetails?.company_industry : ""}
									</p>
								</div>
							</div>
							<div className="flex flex-wrap gap-2 mt-3">
								{company?.companyDetails && (
									<span className="flex items-center bg-gray-200/50 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-3 h-3 mr-1 text-gray-500"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
											/>
										</svg>
										{company?.companyDetails?.company_industry}
									</span>
								)}
								{company?.companyDetails && (
									<span className="flex items-center bg-gray-200/50 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-3 h-3 mr-1 text-gray-500"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
											/>
										</svg>
										{company?.companyDetails?.company_size_approx} employees
									</span>
								)}

								{company?.companyDetails && (
									<span className="flex items-center bg-gray-200/50 text-gray-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth="1.5"
											stroke="currentColor"
											className="w-3 h-3 mr-1 text-gray-500"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
											/>
										</svg>
										<a
											href={company?.companyDetails?.company_website || company?.companyDetails?.linkedin_url}
											target="_blank"
											rel="noopener noreferrer"
											className="hover:underline text-gray-700"
										>
											Website
										</a>
									</span>
								)}
								<span className="flex items-center bg-success-light text-green-700 h-6 px-2.5 py-0.5 rounded-full text-xs font-normal">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										width="24"
										height="24"
										color="#000000"
										fill="none"
										className="w-3 h-3 mr-1 text-success-default"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.75 22.5C5.81294 22.5 1 17.6871 1 11.75C1 5.81294 5.81294 1 11.75 1C17.6871 1 22.5 5.81294 22.5 11.75C22.5 17.6871 17.6871 22.5 11.75 22.5ZM16.5182 9.39018C16.8718 8.9659 16.8145 8.33534 16.3902 7.98177C15.9659 7.62821 15.3353 7.68553 14.9818 8.10981L10.6828 13.2686L8.45711 11.0429C8.06658 10.6524 7.43342 10.6524 7.04289 11.0429C6.65237 11.4334 6.65237 12.0666 7.04289 12.4571L10.0429 15.4571C10.2416 15.6558 10.5146 15.7617 10.7953 15.749C11.076 15.7362 11.3384 15.606 11.5182 15.3902L16.5182 9.39018Z"
											fill="currentColor"
										/>
									</svg>
									Actively Hiring
								</span>
							</div>
						</div>
						<div className="border-t border-gray-200" />
						{company?.jobs?.map((job, jobIndex) => {
							return (
								<div key={jobIndex} className="px-5 py-2">
									<div className="divide-y divide-gray-200">
										<div className="flex justify-between items-center py-4 transition-colors duration-300 ">
											<div
												className="flex items-center cursor-pointer"
												onClick={() => {
													setSelectedJob(job);
													setShowJobDetailsModal(true);
												}}
											>
												<div>
													<div className="flex items-center mb-1">
														<h3 className="text-lg font-semibold mr-2 font-inter transition-colors duration-300 text-gray-900">
															{job.jobTitle}
														</h3>
													</div>
													<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
														<span className="flex items-center mr-3">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth="1.5"
																stroke="currentColor"
																className="w-4 h-4 mr-1"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																/>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
																/>
															</svg>
															{job.remoteWorkSupported === "No" ? "On site" : "Remote"}
														</span>
														<span className="flex items-center mr-3">
															<svg
																xmlns="http://www.w3.org/2000/svg"
																fill="none"
																viewBox="0 0 24 24"
																strokeWidth="1.5"
																stroke="currentColor"
																className="w-4 h-4 mr-1"
															>
																<path
																	strokeLinecap="round"
																	strokeLinejoin="round"
																	d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
																/>
															</svg>
															{dayjs(
																`${job.deadlineDate.year}-${job.deadlineDate.month}-${job.deadlineDate.day}`,
															).format("MMM DD, YYYY")}
														</span>
														{job.salary.minPay !== 1 && (
															<span className="flex items-center">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	viewBox="0 0 24 24"
																	width="24"
																	height="24"
																	fill="none"
																	className="w-4 h-4 mr-1"
																>
																	<path
																		d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
																		stroke="currentColor"
																		strokeWidth="1.5"
																	/>
																	<path
																		d="M14.7102 10.0611C14.6111 9.29844 13.7354 8.06622 12.1608 8.06619C10.3312 8.06616 9.56136 9.07946 9.40515 9.58611C9.16145 10.2638 9.21019 11.6571 11.3547 11.809C14.0354 11.999 15.1093 12.3154 14.9727 13.956C14.836 15.5965 13.3417 15.951 12.1608 15.9129C10.9798 15.875 9.04764 15.3325 8.97266 13.8733M11.9734 6.99805V8.06982M11.9734 15.9031V16.998"
																		stroke="currentColor"
																		strokeWidth="1.5"
																		strokeLinecap="round"
																	/>
																</svg>
																{job.salary.payCurrency} {job.salary.minPay} - {job.salary.payCurrency}{" "}
																{job.salary.maxPay} {job.salary.payFrequency}
															</span>
														)}
													</p>
												</div>
											</div>
											<div className="flex items-center space-x-2">
												<button className="inline-flex items-center justify-center bg-gray-200/50 text-gray-700 h-9 rounded-lg hover:bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden">
													<span className="transition-all duration-300 opacity-100">Save</span>
													<span className="absolute inset-0 flex items-center justify-center transition-all duration-300 opacity-0 transform translate-y-8">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
															/>
														</svg>
													</span>
												</button>
												<button className="btn bg-blue-600">
													<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
													<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
														›
													</span>
												</button>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</>
	);
}

export default Jobs;
