import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../../../components/Loader";
import { cn } from "../../../../handlers/cn.utils";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

function ViewJob({ data, jobs, setShowModal, setData }) {
	const { id } = useParams();
	const navigate = useNavigate();
	const jobService = new ResumeDataService();
	const [loading, setLoading] = useState(true);
	const [jobDetails, setJobDetails] = useState({});
	const [moreJobs, setMoreJobs] = useState([]);

	async function fetchJobs() {
		await jobService.getLatestJobs(
			({ data }) => {
				setMoreJobs(data);
				setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
	}

	useEffect(() => {
		if (id || !jobs) {
			fetchJobs();
		}
	}, [id]);

	useEffect(() => {
		if (jobs) {
			setMoreJobs(jobs);
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		jobService.getSelectedJob(
			id || data?._id,
			(response) => {
				setJobDetails(response.data);
				setLoading(false);
			},
			() => {
				setLoading(false);
			},
		);
	}, [data, id]);

	return (
		<div
			className={cn("h-full w-full", { "fixed top-0 bottom-0 left-0 right-0": data })}
			style={{ ...(data && { background: "rgba(0,0,0,0.4)", zIndex: 9999 }) }}
		>
			<div
				className={cn("h-full bg-white overflow-y-auto", {
					"fixed top-0 right-0 w-full md:w-3/4 lg:w-2/3 xl:w-1/2 shadow-lg": data,
					"container w-full": id,
				})}
				style={{ transform: "translateX(0%) translateZ(0px)", zIndex: 9999 }}
			>
				<div className="p-4 md:p-6 h-full overflow-y-auto">
					{loading && (
						<div className="flex items-center justify-center h-full">
							<Loader />
						</div>
					)}
					{!loading && (
						<>
							{data && (
								<button
									className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
									onClick={() => {
										setShowModal(false);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="h-6 w-6"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor"
									>
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</button>
							)}
							<div className="min-h-screen">
								<div className="max-w-6xl mx-auto px-3 sm:px-4 lg:px-6 py-8">
									<div
										className="cursor-pointer text-blue-600 hover:text-blue-700 mb-8 inline-block text-sm"
										onClick={() => {
											if (data) {
												setShowModal(false);
											} else {
												navigate("/");
											}
										}}
									>
										← Back to Job Listings
									</div>
									<div className="mb-8">
										<div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6">
											<div className="flex items-center mb-4 sm:mb-0">
												<img
													alt={`${jobDetails?.companyName} logo`}
													loading="lazy"
													width="64"
													height="64"
													decoding="async"
													data-nimg="1"
													className="rounded-lg mr-4"
													style={{ color: "transparent" }}
													src={jobDetails?.company ? jobDetails?.company?.logo_url : "https://via.placeholder.com/150"}
												/>
												<div>
													<h3 className="text-2xl font-bold text-gray-900 font-plus-jakarta">{jobDetails?.jobTitle}</h3>
													<p className="text-lg text-gray-700 font-inter">{jobDetails?.companyName}</p>
												</div>
											</div>
										</div>
										<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5 mr-2 text-gray-500"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
													/>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
													/>
												</svg>
												<span className="text-gray-700 text-sm">
													{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
												</span>
											</div>
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5 mr-2 text-gray-500"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
													/>
												</svg>
												<span className="text-gray-700 text-sm">
													Posted on {dayjs(jobDetails?.createdAt).format("MMMM D, YYYY")}
												</span>
											</div>
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5 mr-2 text-gray-500"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
													/>
												</svg>
												<span className="text-gray-700 text-sm">{jobDetails?.employmentType}</span>
											</div>
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5 mr-2 text-gray-500"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
													/>
												</svg>
												<span className="text-gray-700 text-sm">
													{jobDetails?.company?.company_size_approx} Employees
												</span>
											</div>
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													width="24"
													height="24"
													fill="none"
													className="w-5 h-5 mr-2 text-gray-500"
												>
													<path
														d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
														stroke="currentColor"
														strokeWidth="1.5"
													/>
													<path
														d="M14.7102 10.0611C14.6111 9.29844 13.7354 8.06622 12.1608 8.06619C10.3312 8.06616 9.56136 9.07946 9.40515 9.58611C9.16145 10.2638 9.21019 11.6571 11.3547 11.809C14.0354 11.999 15.1093 12.3154 14.9727 13.956C14.836 15.5965 13.3417 15.951 12.1608 15.9129C10.9798 15.875 9.04764 15.3325 8.97266 13.8733M11.9734 6.99805V8.06982M11.9734 15.9031V16.998"
														stroke="currentColor"
														strokeWidth="1.5"
														strokeLinecap="round"
													/>
												</svg>
												<span className="text-gray-700 text-sm">
													{jobDetails?.salary?.payCurrency}
													{jobDetails?.salary?.minPay} - {jobDetails?.salary?.payCurrency}
													{jobDetails?.salary?.maxPay}
												</span>
											</div>
										</div>
										<div className="flex items-center justify-between mb-8">
											<div className="flex items-center space-x-2">
												<div className="cursor-pointer inline-flex items-center justify-center bg-gray-200 text-gray-700 h-10 rounded-sm hover:bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden">
													<span className="transition-all duration-300">Save</span>
													<span className="absolute inset-0 flex items-center justify-center transition-all duration-300 opacity-0 transform translate-y-8">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															fill="none"
															viewBox="0 0 24 24"
															strokeWidth="1.5"
															stroke="currentColor"
															className="w-5 h-5"
														>
															<path
																strokeLinecap="round"
																strokeLinejoin="round"
																d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
															/>
														</svg>
													</span>
												</div>
												<div className="cursor-pointer inline-flex items-center justify-center bg-blue-600 text-white px-4 h-10 rounded-sm hover:bg-blue-700 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden">
													<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
													<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
														›
													</span>
												</div>
											</div>
											<button className="text-sm text-gray-500 hover:text-gray-700 transition-colors duration-300">
												Not Relevant
											</button>
										</div>
										<div className="mb-6">
											<h2 className="text-base font-semibold mb-2 font-inter">Job Description</h2>
											<div
												className="text-gray-700 font-inter"
												dangerouslySetInnerHTML={{
													__html: jobDetails?.jobDescription?.lookingToHire,
												}}
											/>
										</div>
										<div className="mb-6">
											<h2 className="text-base font-semibold mb-2 font-inter">Responsibilities</h2>
											<ul className="list-disc list-inside text-gray-700 font-inter">
												{jobDetails?.jobDescription?.responsibilities?.map((item, index) => {
													return <li key={index}>{item.value}</li>;
												})}
											</ul>
										</div>
										<div className="mb-6">
											<h2 className="text-base font-semibold mb-2 font-inter">Requirements</h2>
											<ul className="list-disc list-inside text-gray-700 font-inter">
												{jobDetails?.jobDescription?.qualifications?.map((item, index) => {
													return <li key={index}>{item.value}</li>;
												})}
											</ul>
										</div>
									</div>
									<div className="rounded-lg border border-gray-200 bg-white shadow-md overflow-hidden">
										<div className="p-6 pt-6 bg-gray-200/50 border-b border-gray-200">
											<h3 className="text-lg text-base font-semibold font-plus-jakarta">
												About {jobDetails?.companyName}
											</h3>
										</div>
										<div className="pt-6 p-6">
											<div className="flex items-center mb-4">
												<div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0 border border-gray-100 mr-4">
													<img
														alt={`${jobDetails?.companyName} logo`}
														loading="lazy"
														width="64"
														height="64"
														decoding="async"
														data-nimg="1"
														className="w-full h-full object-cover"
														style={{ color: "transparent" }}
														src={
															jobDetails?.company ? jobDetails?.company?.logo_url : "https://via.placeholder.com/150"
														}
													/>
												</div>
												<div>
													<p className="text-gray-700 mt-1 font-inter text-sm">
														Leading technology solutions provider in Tanzania, focused on building innovative solutions
														for the African market. Our mission is to transform industries through cutting-edge
														technology.
													</p>
												</div>
											</div>
											<div className="flex flex-wrap gap-2 text-sm text-gray-600 font-inter mb-4">
												<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1 text-gray-600"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
														/>
													</svg>
													{jobDetails?.company?.company_industry}
												</span>
												<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1 text-gray-600"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
														/>
													</svg>
													{jobDetails?.company?.company_size_approx} Employees
												</span>
												<span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200/50 text-gray-800">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1 text-gray-600"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
														/>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
														/>
													</svg>
													{jobDetails?.jobLocation?.city}, {jobDetails?.jobLocation?.region}
												</span>
												<span
													className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-green-800"
													style={{ color: "#34663D", backgroundColor: "#E2FBE8" }}
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														strokeWidth="1.5"
														stroke="currentColor"
														className="w-3 h-3 mr-1 text-green-600"
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
														/>
													</svg>
													Actively Hiring
												</span>
											</div>
											<div className="flex items-center">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5 mr-2 text-gray-600"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"
													/>
												</svg>
												<span className="font-semibold mr-1 text-sm">Website:</span>
												<a
													href={jobDetails?.company?.company_website}
													target="_blank"
													rel="noopener noreferrer"
													className="text-primary-700 hover:underline text-sm"
												>
													{jobDetails?.company?.company_website}
												</a>
											</div>
										</div>
									</div>
									<div className="mt-12">
										<h2 className="text-lg font-semibold mb-4 font-plus-jakarta">More Job Opportunities</h2>
										<div>
											{moreJobs
												.filter((item) => item?._id !== jobDetails?._id)
												.map((item, index) => {
													return (
														<div
															key={index}
															className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300"
														>
															<div
																className="flex items-center cursor-pointer"
																onClick={() => {
																	if (data) {
																		setData(item);
																	} else {
																		navigate(`/jobs/${item?._id}`);
																	}
																}}
															>
																<div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center overflow-hidden border border-gray-100">
																	<img
																		alt="AfriDesign Solutions logo"
																		loading="lazy"
																		width="48"
																		height="48"
																		decoding="async"
																		data-nimg="1"
																		className="w-full h-full object-contain"
																		src={item?.company ? item?.company?.logo_url : "https://via.placeholder.com/150"}
																	/>
																</div>
																<div>
																	<div className="flex items-center mb-1">
																		<h3 className="text-sm font-semibold mr-2 font-inter transition-colors duration-300 text-gray-900">
																			{item?.jobTitle}
																		</h3>
																		{item?.company?.company_industry && (
																			<span className="text-xs px-2 rounded-full bg-gray-200/50 text-gray-800 h-5 flex items-center justify-center mr-2">
																				{item?.company?.company_industry}
																			</span>
																		)}
																	</div>
																	<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
																		<span className="mr-3">{item?.companyName}</span>
																		<span className="flex items-center mr-3">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				fill="none"
																				viewBox="0 0 24 24"
																				strokeWidth="1.5"
																				stroke="currentColor"
																				className="w-4 h-4 mr-1"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																				/>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
																				/>
																			</svg>
																			{item?.jobLocation.city}, {item?.jobLocation.region}
																		</span>
																		<span className="flex items-center mr-3">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				fill="none"
																				viewBox="0 0 24 24"
																				strokeWidth="1.5"
																				stroke="currentColor"
																				className="w-4 h-4 mr-1"
																			>
																				<path
																					strokeLinecap="round"
																					strokeLinejoin="round"
																					d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
																				/>
																			</svg>
																			{dayjs(
																				`${item.deadlineDate.year}-${item.deadlineDate.month}-${item.deadlineDate.day}`,
																			).format("MMM DD, YYYY")}
																		</span>
																	</p>
																</div>
															</div>
															<div className="flex items-center space-x-2">
																<div className="cursor-pointer inline-flex items-center justify-center bg-gray-200 text-gray-700 h-10 rounded-sm hover:bg-gray-300 transition-colors text-sm font-inter w-[70px] relative overflow-hidden">
																	<span className="transition-all duration-300 opacity-100">Save</span>
																	<span className="absolute inset-0 flex items-center justify-center transition-all duration-300 opacity-0 transform translate-y-8">
																		<svg
																			xmlns="http://www.w3.org/2000/svg"
																			fill="none"
																			viewBox="0 0 24 24"
																			strokeWidth="1.5"
																			stroke="currentColor"
																			className="w-5 h-5"
																		>
																			<path
																				strokeLinecap="round"
																				strokeLinejoin="round"
																				d="M17.593 3.322c1.1.128 1.907 1.077 1.907 2.185V21L12 17.25 4.5 21V5.507c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0Z"
																			/>
																		</svg>
																	</span>
																</div>
																<div className="cursor-pointer inline-flex items-center justify-center bg-blue-600 text-white px-4 h-10 rounded-sm hover:bg-blue-700 transition-colors duration-300 text-sm font-inter w-[90px] group relative overflow-hidden">
																	<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
																	<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
																		›
																	</span>
																</div>
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default ViewJob;
