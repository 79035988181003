import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import Loader from "../../components/Loader";
import { ResumeDataService } from "../../services/create-resume/resume-data";
import Header from "../StaticPages/Header";
import Categories from "./components/Categories";
import Jobs from "./components/Jobs";
import Search from "./components/Search";

function JobsPage() {
	const location = useLocation();
	const { searchedJobTitle, searchedAddress, jobSearchSource } = location.state || {};
	const apiService = new ResumeDataService();

	const [categories, setCategories] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState("All");
	const [jobs, setJobs] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	function fetchJobs() {
		apiService.getUpcomingJobs(
			({ data }) => {
				setJobs(
					data.map((item) => {
						return { jobs: [item], companyDetails: item.company };
					}),
				);
				setLoading(false);
			},
			(error) => {
				toast.error(error.message);
				setLoading(false);
			},
		);
	}

	React.useEffect(() => {
		if (searchedJobTitle === undefined && searchedAddress === undefined) {
			fetchJobs();
		}
	}, []);

	useEffect(() => {
		const categories = jobs.map((job) => job?.jobs?.[0]?.jobExpertise);
		const uniqueCategories = [...new Set(categories)];
		setCategories(uniqueCategories);
	}, [jobs]);

	return (
		<>
			<Header activeLink="jobs" />

			<section className="container-xxl mx-auto lg:px-36 !py-32">
				<div className="flex flex-col lg:flex-row gap-8 px-4">
					<div className="lg:w-3/4">
						<Search
							setJobs={setJobs}
							setLoading={setLoading}
							searchedAddress={searchedAddress}
							jobSearchSource={jobSearchSource}
							selectedCategory={selectedCategory}
							searchedJobTitle={searchedJobTitle}
							setSelectedCategory={setSelectedCategory}
						/>
						{loading && (
							<div className="mt-5">
								<Loader />
							</div>
						)}
						{!loading && (
							<>
								<h2 className="text-3xl font-bold mb-2 text-gray-900 font-plus-jakarta">{selectedCategory} Jobs</h2>
								<p className="text-sm text-gray-700 mb-6 font-inter">
									Showing {jobs.length} companies with job openings
								</p>
								<Jobs jobs={jobs} />
							</>
						)}
					</div>
					<div className="lg:w-1/4 space-y-4">
						<Categories
							categories={categories}
							setSelectedCategory={setSelectedCategory}
							selectedCategory={selectedCategory}
						/>
					</div>
				</div>
			</section>
		</>
	);
}

export default JobsPage;
