import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React from "react";

import Footer from "../Footer";
import Header from "../Header";
import FeaturedEmployers from "./components/FeaturedEmployers";
import JobsByCategory from "./components/JobsByCategory";
import LatestJobs from "./components/LatestJobs";
import OverTheFold from "./components/OverTheFold";
// import RecommendedJobs from "./components/RecommendedJobs";
import Resources from "./components/Resources";

function HomePage() {
	return (
		<>
			<Header activeLink="home" />

			<OverTheFold />

			<LatestJobs />

			<FeaturedEmployers />

			<JobsByCategory />

			{/* <RecommendedJobs /> TODO: Replace with upsell */}

			<Resources />

			<section className="bg-gray-900 pt-24 pb-16 px-4 md:px-8">
				<div className="max-w-4xl mx-auto text-center">
					<h2 className="text-3xl md:text-4xl font-bold mb-4 text-white font-plus-jakarta">
						Are you an employer looking for talent?
					</h2>
					<p className="text-xl text-gray-400 opacity-80 mb-8 font-inter">
						Post your job listings on Dproz and connect with the best candidates in Tanzania.
					</p>
					<a
						className="inline-block bg-blue-500 text-white px-8 h-10 leading-10 !rounded text-lg font-medium hover:bg-blue-600 transition-colors font-inter"
						href="/post-job"
					>
						Post a Job Now
					</a>
				</div>
			</section>

			<Footer />
		</>
	);
}

export default HomePage;
