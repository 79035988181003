import accounting from "./accounting.json";
import administrationAndOfficeSupport from "./administrationAndOfficeSupport.json";
import advertisingArtsAndmedia from "./advertisingArtsAndmedia.json";
import agriculture from "./agriculture.json";
import bankingandfinance from "./bankingandfinance.json";
import communityServicesAndDevelopment from "./communityServicesAndDevelopment.json";
import companyIndustries from "./companyIndustries.json";
import consultingAndStrategy from "./consultingAndStrategy.json";
import customerService from "./customerService.json";
import designAndArchitecture from "./designAndArchitecture.json";
import districtsRegions from "./districtsRegions.json";
import education from "./education.json";
import engineering from "./engineering.json";
import executive from "./executive.json";
import governmentAndDefense from "./governmentAndDefense.json";
import healthcareAndMedical from "./healthcareAndMedical.json";
import humanResourcesAndRecruitment from "./humanResourcesAndRecruitment.json";
import informationTechnology from "./informationTechnology.json";
import insurance from "./insurance.json";
import languages from "./languages.json";
import legal from "./legal.json";
import marketingAndCommunications from "./marketingAndCommunication.json";
import premiumTemplatesConfigurations from "./premium_templates_configurations.json";
import proficiency from "./proficiency.json";
import realEstateAndProperty from "./realEstateAndProperty.json";
import retailConsumer from "./retailConsumer.json";
import sportsAndRecreation from "./sportsAndRecreation.json";

const mapping = {};

mapping["banking and finance"] = bankingandfinance;
mapping["design and architecture"] = designAndArchitecture;
mapping["education"] = education;
mapping["engineering"] = engineering;
//mapping["functions and skills"] = functionAndSkills;  this will be handled separately
mapping["information technology"] = informationTechnology;
mapping["insurance"] = insurance;
mapping["marketing and communications"] = marketingAndCommunications;
mapping["Accounting"] = accounting;
mapping["Administration & Office Support"] = administrationAndOfficeSupport;
mapping["Advertising Arts & Media"] = advertisingArtsAndmedia;
mapping["Community Services & Development"] = communityServicesAndDevelopment;
mapping["Consulting and Strategy"] = consultingAndStrategy;
mapping["Customer Service"] = customerService;
mapping["Executive"] = executive;
mapping["Healthcare and Medical"] = healthcareAndMedical;
mapping["Human Resources & Recruitment"] = humanResourcesAndRecruitment;
mapping["Legal"] = legal;
mapping["Real Estate and Property"] = realEstateAndProperty;
mapping["Retail & Consumer"] = retailConsumer;
mapping["Government and Defense"] = governmentAndDefense;
mapping["Sports and Recreation"] = sportsAndRecreation;
mapping["Agriculture"] = agriculture;
mapping["districtsRegions"] = districtsRegions;
mapping["languages"] = languages;
mapping["proficiency"] = proficiency;
mapping["Premium Templates Configurations"] = premiumTemplatesConfigurations;
mapping["companyIndustries"] = companyIndustries.industries;

export default mapping;
