import React from "react";

function Accordion({ label, children, errorStatus }) {
	const [showContent, setShowContent] = React.useState(false);
	return (
		<div className={`${errorStatus && "border-danger"} rounded border-2 p-2`}>
			<div className="accordion-item">
				<p
					className={`accordion-header m-0 p-0 font-bold text-gray-500 ${showContent ? "mb-3" : "collapsed"}`}
					onClick={() => setShowContent(!showContent)}
				>
					{label}
				</p>
				{showContent && <div className="accordion-body">{children}</div>}
			</div>
		</div>
	);
}

export default Accordion;
