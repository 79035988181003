import dayjs from "dayjs";
import React, { useEffect } from "react";
import toast from "react-hot-toast";

import { cn } from "../../../../handlers/cn.utils";
import { ResumeDataService } from "../../../../services/create-resume/resume-data";

function JobsByCategory() {
	const apiService = new ResumeDataService();

	const [categories, setCategories] = React.useState([]);
	const [selectedCategory, setSelectedCategory] = React.useState("All");
	const [jobs, setJobs] = React.useState([]);
	const [, setLoading] = React.useState(true);

	function fetchJobs() {
		apiService.getUpcomingJobs(
			({ data }) => {
				setLoading(false);
				setJobs(data);
			},
			(error) => {
				setLoading(false);
				toast.error(error.message);
			},
		);
	}

	React.useEffect(() => {
		fetchJobs();
	}, []);

	useEffect(() => {
		const categories = jobs.map((job) => job.jobExpertise);
		const uniqueCategories = [...new Set(categories)];
		setCategories(uniqueCategories);
	}, [jobs]);

	const [filteredJobs, setFilteredJobs] = React.useState([...jobs]);

	React.useEffect(() => {
		if (selectedCategory === "All") {
			setFilteredJobs(jobs);
		} else {
			const filtered = jobs.filter((job) => job.jobExpertise === selectedCategory);

			setFilteredJobs(filtered);
		}
	}, [selectedCategory, jobs]);

	const filtersContainerRef = React.useRef(null);

	function handleScroll(direction) {
		const container = filtersContainerRef.current;
		const scrollAmount = 200;

		if (direction === "right") {
			container.scrollLeft += scrollAmount;
		} else {
			container.scrollLeft -= scrollAmount;
		}
	}

	return (
		<section className="py-12 relative overflow-hidden">
			<div className="relative z-10 max-w-6xl mx-auto">
				<h2 className="!text-4xl font-bold mb-8 text-gray-900 font-plus-jakarta">Job Listings by Category</h2>
				<div className="relative mb-8 overflow-hidden">
					<button
						className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white rounded-lg p-2 shadow-md z-10"
						onClick={() => handleScroll("right")}
					>
						<span className="text-2xl">›</span>
					</button>
					<div className="flex overflow-x-hidden scrollbar-hide !pb-4" ref={filtersContainerRef}>
						<div className="flex w-full gap-3">
							<span
								className={cn(
									"flex-1 px-6 py-3 rounded text-[14px] min-w-fit cursor-pointer text-center font-medium transition-all duration-300 transform hover:scale-105 hover:shadow-md bg-[#fff] text-gray-700 !shadow",
									{
										"!bg-gray-900 text-[white]": selectedCategory === "All",
									},
								)}
								onClick={() => setSelectedCategory("All")}
							>
								All
							</span>
							{categories.map((category, index) => (
								<span
									className={cn(
										"flex-1 px-6 py-3 min-w-fit cursor-pointer rounded text-[14px] text-center font-medium transition-all duration-300 transform hover:scale-105 hover:shadow-md bg-[#fff] text-gray-700 ml-2 !shadow",
										{
											"!bg-gray-700 text-[white] focus:text-[white]": selectedCategory === category,
										},
									)}
									onClick={() => setSelectedCategory(category)}
									key={index}
								>
									{category}
								</span>
							))}
						</div>
					</div>
				</div>
				<div className="relative overflow-hidden rounded-lg">
					<div className="absolute inset-0 bg-[linear-gradient(60deg,_#ffffff_0%,_var(--tw-color-primary-50)_50%,_#ffffff_100%)] opacity-50 backdrop-filter backdrop-blur-sm" />
					<div className="relative z-10">
						{filteredJobs.map((job, index) => (
							<div
								key={index}
								className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300 "
							>
								<div className="flex items-center cursor-pointer">
									<div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center overflow-hidden border border-gray-100">
										<img
											alt="BuildWell Construction logo"
											loading="lazy"
											width="48"
											height="48"
											decoding="async"
											data-nimg="1"
											className="w-full h-full object-contain"
											src={job.company ? job.company.logo_url : "https://picsum.photos/200?random=" + index}
										/>
									</div>
									<div>
										<div className="flex items-center mb-1">
											<h3 className="text-base font-semibold mr-2 font-inter transition-colors duration-300 text-gray-900">
												{job.jobTitle}
											</h3>
										</div>
										<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
											<span className="mr-3">{job.companyName}</span>
											<span className="flex items-center mr-3">
												<i className="fa-solid fa-location-dot mr-1 text-gray-400" />
												{job.jobLocation.city}, {job.jobLocation.region}
											</span>
											{job.deadlineDate.month && (
												<span className="flex items-center mr-3">
													<i className="fa-regular fa-calendar mr-1 text-gray-400" />
													{dayjs(`${job.deadlineDate.month}-${job.deadlineDate.day}-${job.deadlineDate.year}`).format(
														"MMM DD, YYYY",
													)}
												</span>
											)}
										</p>
									</div>
								</div>
								<div className="flex items-center space-x-2">
									<button className="btn text-black bg-gray-200 hover:bg-gray-300 font-medium transition-colors duration-300 w-[90px] group relative overflow-hidden">
										Save
									</button>
									<button className="btn text-white bg-blue-600 font-medium transition-colors duration-300 w-[90px] group relative overflow-hidden">
										Apply
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300">
					<div className="mt-8 mx-auto">
						<a
							className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"
							href={`/jobs?category=${selectedCategory}`}
						>
							View {selectedCategory !== "All" ? "" : "All " + selectedCategory} Jobs
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default JobsByCategory;
