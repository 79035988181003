import "../css/global.css";
import "../css/home.css";
import "../css/responsive.css";

import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

import AuthContext from "../../../context/Auth/AuthContext";
import { cn } from "../../../handlers/cn.utils";
import useIsLoggedIn from "../../../hooks/use-IsLoggedIn";

function Header({ activeLink }) {
	const userLoggedIn = useIsLoggedIn();

	const [activeAboutUsLink, setActiveAboutUsLink] = useState(false);
	const [activeResumeTipsLink, setActiveResumeTipsLink] = useState(false);

	const { setUserData } = useContext(AuthContext);

	useEffect(() => {
		if (activeLink === "home") {
			setActiveAboutUsLink(false);
			setActiveResumeTipsLink(false);
		}

		if (activeLink === "about") {
			setActiveAboutUsLink(true);
			setActiveResumeTipsLink(false);
		}

		if (activeLink === "tips") {
			setActiveAboutUsLink(false);
			setActiveResumeTipsLink(true);
		}
	}, []);

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	function toggleMenu() {
		setShowMobileMenu(!showMobileMenu);
	}

	const [__, _, removeCookie] = useCookies(["Authorization"]);

	return (
		<header className="bg-white shadow-sm">
			<div className="container">
				<div className="flex items-center justify-between py-3 md:px-0 px-3">
					<Link to="/" className="logo mr-2">
						<img src="/images/home/logo.svg" alt="Dproz logo" />
					</Link>

					<div
						className={cn({
							"flex flex-col items-start px-2 absolute bg-white w-full top-[60px] shadow-sm pb-6 left-0":
								showMobileMenu,
							"hidden md:flex": !showMobileMenu,
						})}
					>
						<ul
							className={cn("flex items-center justify-evenly 2xl:gap-4", {
								"flex-col justify-start items-start gap-1": showMobileMenu,
							})}
						>
							<li>
								<Link
									to="/jobs"
									className={cn("btn text-gray-800 font-medium hover:bg-blue-100 hover:text-blue-700", {
										"bg-blue-100 text-blue-700": activeLink === "jobs",
									})}
								>
									Jobs
								</Link>
							</li>

							<li>
								<Link
									to="/resume-tips"
									className={cn("btn text-gray-800 font-medium hover:bg-blue-100 hover:text-blue-700", {
										"bg-blue-100 text-blue-700": activeResumeTipsLink,
									})}
								>
									Resume Tips
								</Link>
							</li>

							<li>
								<a
									href="https://hiring.dproz.com/"
									className="btn text-gray-800 font-medium"
									target="_blank"
									rel="noreferrer"
								>
									For Employers
								</a>
							</li>

							<li>
								<Link
									to="/blog/job-hunt-challenges"
									className={cn("btn text-gray-800 font-medium hover:bg-blue-100 hover:text-blue-700", {
										"bg-blue-100 text-blue-700": window.location.pathname.includes("/blog"),
									})}
								>
									Blog
								</Link>
							</li>
							<li>
								<Link
									to="/about-us"
									className={cn("btn text-gray-800 font-medium hover:bg-blue-100 hover:text-blue-700", {
										"bg-blue-100 text-blue-700": activeAboutUsLink,
									})}
								>
									About us
								</Link>
							</li>
							<li>
								<Link
									to="/payments"
									className={cn("btn text-gray-800 font-medium hover:bg-blue-100 hover:text-blue-700", {
										"bg-blue-100 text-blue-700": activeLink === "plans",
									})}
								>
									Plans
								</Link>
							</li>
						</ul>
					</div>

					<button className="btn bg-blue-500 ml-auto d-md-none menu-open" onClick={toggleMenu}>
						{!showMobileMenu ? <span className="fa fa-bars" /> : <span className="fa fa-times" />}
					</button>

					<div className="hidden md:flex items-center">
						<Link to="/resume/select-experience" className="btn text-white bg-blue-600 font-medium">
							Create Resume
						</Link>
						<div className="dropdown lg:ml-3">
							<button
								className="btn border-0 text-blue-600 font-medium hover:text-blue-500"
								type="button"
								data-toggle="dropdown"
								aria-expanded="false"
							>
								{userLoggedIn ? "My Resumes" : "Login"}
							</button>
							{userLoggedIn ? (
								<div className="dropdown-menu">
									<Link className="dropdown-item" to="/my-resumes">
										My Resumes
									</Link>
									<Link className="dropdown-item" to="/dashboard">
										Dashboard
									</Link>
									<Link
										className="dropdown-item"
										to="/signin"
										onClick={() => {
											removeCookie("Authorization");
											localStorage.removeItem("profileData");
											setUserData(null);
											window.location.href = "/signin";
										}}
									>
										Logout
									</Link>
								</div>
							) : (
								<div className="dropdown-menu">
									<Link className="dropdown-item" to="/signin">
										Log In
									</Link>
									<Link className="dropdown-item" to="/register">
										Register
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
