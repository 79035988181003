import React, { useEffect, useState } from "react";

import EditableText from "../../../components/EditableText/";
import Tabs from "../../../components/Tabs/";
import TextEditor from "../../../components/TextEditor";
import dropDownOptions from "../../../seed/dropDownOptions";

const TABS = [{ name: "Overview", key: "overview" }];

function DetailSection({ profileData, onChange, hasEditDetails, onSubmit, onResetChanges }) {
	const companyTypes = dropDownOptions.companyTypes;
	const companyIndustries = dropDownOptions.companyIndustriesOptions;
	const [currentView, setCurrentView] = useState("overview");
	const [companyDescription, setCompanyDescription] = useState(profileData.about_us);

	useEffect(() => {
		onChange("companyDescription", companyDescription);
	}, [companyDescription]);

	return (
		<div className="w-full">
			<div className="flex items-center flex-wrap justify-between w-full">
				<div>
					<Tabs currentView={currentView} setCurrentView={setCurrentView} tabs={TABS} />
				</div>
				{hasEditDetails && (
					<div className="space-x-4">
						<button className="btn btn-secondary" onClick={onResetChanges}>
							Cancel
						</button>
						<button
							className="btn btn-primary btn-lg"
							onClick={(event) => {
								onSubmit(event);
							}}
						>
							Save
						</button>
					</div>
				)}
			</div>
			<div className="w-full mt-4 mb-32">
				{currentView === "overview" && (
					<div className="flex flex-col space-y-4">
						<div>
							<h4>Overview</h4>
							<TextEditor
								value={companyDescription}
								id="companyDescriptionEdit"
								setValue={setCompanyDescription}
								placeholder="Add Company Description here..."
							/>
						</div>
						<div>
							<h6>Founded</h6>
							<EditableText value={profileData.founded || "---"} onChange={(value) => onChange("founded", value)} />
						</div>
						<div>
							<h6>Company Type</h6>
							<EditableText
								value={profileData.type || "---"}
								onChange={(value) => onChange("type", value)}
								type="select"
								options={companyTypes.map((item) => {
									return { label: item, value: item };
								})}
							/>
						</div>
						<div>
							<h6>Industry</h6>
							<EditableText
								value={profileData.company_industry || "---"}
								onChange={(value) => onChange("company_industry", value)}
								type="select"
								options={companyIndustries.map((item) => {
									return { label: item, value: item };
								})}
							/>
						</div>
						<div>
							<h6>Company size</h6>
							<EditableText
								value={profileData.company_size_approx || "---"}
								onChange={(value) => onChange("company_size_approx", value)}
								type="select"
								options={[
									{ label: "1-10", value: "1-10" },
									{ label: "11 - 50", value: "11 - 50" },
									{ label: "51 - 100", value: "51 - 100" },
									{ label: "101-500", value: "101-500" },
									{ label: "above-500", value: "above-500" },
								]}
							/>
						</div>
						<div>
							<h6>Headquarters</h6>
							<EditableText
								value={`${profileData?.headquarters || "---"}`}
								onChange={(value) => onChange("headquarters", value)}
							/>
						</div>
						<div>
							<h6>Locations</h6>
							<EditableText
								value={`${profileData?.locations || "---"}`}
								onChange={(value) => onChange("locations", value)}
							/>
						</div>
						<div>
							<h6>Specialties</h6>
							<EditableText
								value={`${profileData?.specialties || "---"}`}
								onChange={(value) => onChange("specialties", value)}
							/>
						</div>
						<div>
							<h6>Funding</h6>
							<EditableText
								value={`${profileData?.funding || "---"}`}
								onChange={(value) => onChange("funding", value)}
							/>
						</div>
						<div>
							<h6>Funding Total Rounds</h6>
							<EditableText
								value={`${profileData?.funding_total_rounds || "---"}`}
								onChange={(value) => onChange("funding_total_rounds", value)}
							/>
						</div>
						<div>
							<h6>Last Funding Round</h6>
							<EditableText
								value={`${profileData?.last_funding_round || "---"}`}
								onChange={(value) => onChange("last_funding_round", value)}
							/>
						</div>
						<div>
							<h6>Funding Option</h6>
							<EditableText
								value={`${profileData?.funding_option || "---"}`}
								onChange={(value) => onChange("funding_option", value)}
							/>
						</div>
						<div>
							<h6>Website</h6>
							<EditableText value={profileData.website || "---"} onChange={(value) => onChange("website", value)} />
						</div>
						<div>
							<h6>Linkedin URL</h6>
							<EditableText
								value={profileData.linkedin_url || "---"}
								onChange={(value) => onChange("linkedin_url", value)}
							/>
						</div>
						<div>
							<h6>Linkedin Followers Count</h6>
							<EditableText
								value={profileData.linkedin_followers_count || "---"}
								onChange={(value) => onChange("linkedin_followers_count", value)}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default DetailSection;
