import dayjs from "dayjs";
import React, { useState } from "react";
import toast from "react-hot-toast";

import { ResumeDataService } from "../../../../services/create-resume/resume-data";
import ViewJob from "./ViewJob";

function LatestJobs() {
	const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
	const [selectedJob, setSelectedJob] = useState(null);
	const apiService = new ResumeDataService();

	const [jobs, setJobs] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	async function fetchJobs() {
		await apiService.getLatestJobs(
			({ data }) => {
				setJobs(data);
				setLoading(false);
			},
			(error) => {
				toast.error(error.message);
				setLoading(false);
			},
		);
	}

	React.useEffect(() => {
		fetchJobs();
	}, []);

	return (
		<section className="bg-white py-12 px-4 md:px-8">
			{showJobDetailsModal && (
				<ViewJob data={selectedJob} jobs={jobs} setShowModal={setShowJobDetailsModal} setData={setSelectedJob} />
			)}
			<div className="max-w-6xl mx-auto">
				<h2 className="text-3xl font-bold mb-8 text-gray-900 font-plus-jakarta">Latest Job Postings</h2>
				{loading ? (
					<div className="animate-pulse">
						{[...new Array(3)].map((_, index) => (
							<div className="h-24 bg-gray-900 opacity-10 mb-3 rounded-md" key={index} />
						))}
					</div>
				) : (
					<div className="divide-y divide-gray-200">
						{jobs.map((job, index) => (
							<React.Fragment key={index}>
								<div className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300">
									<div
										className="flex items-center cursor-pointer"
										onClick={() => {
											setSelectedJob(job);
											setShowJobDetailsModal(true);
										}}
									>
										<div className="w-12 h-12 rounded-lg mr-4 flex items-center justify-center overflow-hidden border border-gray-100">
											<img
												alt={`${job.companyName} logo`}
												loading="lazy"
												width="48"
												height="48"
												decoding="async"
												data-nimg="1"
												className="w-full h-full object-contain"
												src={job.company ? job.company.logo_url : "https://via.placeholder.com/150"}
											/>
										</div>
										<div>
											<div className="flex items-center mb-1">
												<h3 className="text-base font-semibold mr-2 font-inter transition-colors duration-300 text-gray-900">
													{job.jobTitle}
												</h3>
											</div>
											<p className="text-[14px] text-gray-600 flex items-center flex-wrap">
												<span className="mr-3">{job.companyName}</span>
												<span className="flex items-center mr-3">
													<i className="fa-solid fa-location-dot mr-1 text-gray-400" />
													{job.jobLocation.city}, {job.jobLocation.region}
												</span>
												<span className="flex items-center mr-3">
													<i className="fa-regular fa-calendar mr-1 text-gray-400" />
													{dayjs(`${job.deadlineDate.year}-${job.deadlineDate.month}-${job.deadlineDate.day}`).format(
														"MMM DD, YYYY",
													)}
												</span>
											</p>
										</div>
									</div>
									<div className="flex items-center space-x-2">
										<button className="btn text-black bg-gray-200 hover:bg-gray-300 font-medium transition-colors duration-300 w-[90px] group relative overflow-hidden">
											Save
										</button>
										<button className="btn text-white bg-blue-600 font-medium transition-colors duration-300 w-[90px] group relative overflow-hidden">
											<span className="mr-1 group-hover:mr-3 transition-all duration-300">Apply</span>
											<span className="absolute right-4 opacity-0 transition-all duration-300 transform translate-x-2 group-hover:opacity-100 group-hover:translate-x-0">
												›
											</span>
										</button>
									</div>
								</div>
							</React.Fragment>
						))}
						<div className="flex justify-between items-center py-4 border-b border-gray-200 transition-colors duration-300">
							<div className="mt-8 mx-auto">
								<a
									className="inline-block bg-[#fff] border border-gray-200 text-gray-700 px-6 py-2 rounded hover:bg-gray-50 transition-colors font-inter"
									href="/jobs"
								>
									View All Jobs
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</section>
	);
}
export default LatestJobs;
