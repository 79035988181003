import React, { useState } from "react";

import SearchableSelectInput from "../SearchableSelectInput/SearchableSelectInput";

function EditableText({ value, onChange, type, options }) {
	const [editing, setEditing] = useState(false);

	const [inputValue, setInputValue] = useState(value);

	const handleBlur = () => {
		setEditing(false);
		onChange(inputValue);
	};

	return (
		<div>
			{editing ? (
				type === "select" ? (
					<SearchableSelectInput
						defaultLabel="Select"
						value={inputValue}
						options={options}
						onSelect={(value) => {
							setInputValue(value);
							onChange(value);
							setEditing(false);
						}}
					/>
				) : (
					<input
						type="text"
						value={inputValue}
						onChange={(e) => setInputValue(e.target.value)}
						onBlur={handleBlur}
						onKeyDown={(e) => e.key === "Enter" && handleBlur()}
						autoFocus
						className="box-border w-full rounded-sm border border-gray-200 bg-white p-2 text-sm leading-none focus:border-gray-200 focus:outline-none focus:ring-0"
					/>
				)
			) : (
				<div onClick={() => setEditing(true)}>{value}</div>
			)}
		</div>
	);
}

export default EditableText;
