import React from "react";

import Icon from "../Icon";

function DropdownOptions({ data, onSelect }) {
	return (
		<div>
			{data.map((item, index) => (
				<div
					key={index}
					className="flex cursor-pointer items-center rounded p-2 hover:bg-background"
					onClick={() => onSelect(item)}
				>
					{item.icon && <Icon name={item.icon} size={16} className="mr-2" />}
					<span>{item.label}</span>
				</div>
			))}
		</div>
	);
}

export default DropdownOptions;
