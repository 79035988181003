import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";

import ExpertResumesLayout from "../../../components/ExpertResumesLayout/ExpertResumesLayout";
import Loader from "../../../components/Loader";
import { getJobsRoutes } from "../../../handlers/navigationItems";
import { returnDocumentMIMEType } from "../../../handlers/valueTransformation";
import { ResumeDataService } from "../../../services/create-resume/resume-data";
import ReviewModal from "./ReviewModal";

function JobDescription() {
	const apiService = new ResumeDataService();

	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState("");
	const [jobDescription, setJobDescription] = useState(null);

	const { id } = useParams();

	useEffect(() => {
		apiService.getJobDescriptionByID(
			id,
			(data) => {
				setLoading(false);
				setJobDescription(data.data);
			},
			(e) => {
				setLoading(false);
				const error = e.response.data;
				setErrors(error);
				toast.error("Something went wrong!");
			},
		);
	}, [id]);

	const [showReviewModal, setShowReviewModal] = useState(false);

	return (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Job Descriptions" headingIcon="arrow-left">
			<div className="my-resumes-section">
				<div className="container">
					{loading ? (
						<Loader />
					) : (
						<>
							{errors && (
								<div className="alert alert-danger" role="alert">
									{errors}
								</div>
							)}
						</>
					)}

					{jobDescription && (
						<div className="card card-body p-4 rounded">
							<div className="row mb-3">
								<div className="col-sm-12 col-md-7">
									<div className="gap-0">
										<h4 className="m-0 mr-3">{jobDescription.title}</h4>
										<h5 className="m-0 col-md-6 p-0">{jobDescription.company}</h5>
									</div>
									<p className="m-0 text-primary h5">{jobDescription.screeningMode.replaceAll("_", " ")}</p>
									<p className="m-0">Added at: {dayjs(jobDescription.addedAt).format("DD MMM YYYY, h:mm A")}</p>
									<p className="m-0 mb-3">
										Reviewed on: {dayjs(jobDescription.lastReviewedOn).format("DD MMM YYYY, h:mm A")}
									</p>
								</div>
								<div className="col-5 p-0">
									<div className="d-flex p-0 justify-content-end">
										<button className="btn btn-primary mr-2" onClick={() => setShowReviewModal(true)}>
											Review
										</button>
									</div>
								</div>
							</div>

							{returnDocumentMIMEType(jobDescription.fileName) === "application/pdf" ? (
								<div className="card card-body p-0">
									<embed
										src={jobDescription.detailBase64OrURL}
										type={returnDocumentMIMEType(jobDescription.fileName)}
										width="100%"
										height="900px"
									/>
								</div>
							) : (
								<>
									<Link to={jobDescription.detailBase64OrURL} target="_blank" className="btn btn-primary">
										Download Job Description
									</Link>
								</>
							)}
						</div>
					)}
				</div>
			</div>
			{showReviewModal && <ReviewModal jdID={jobDescription._id} setShowReviewModal={setShowReviewModal} />}
		</ExpertResumesLayout>
	);
}

export default JobDescription;
