import React, { useState } from "react";

function Tooltip({ children, message }) {
	const [visible, setVisible] = useState(false);

	return (
		<div className="relative inline-block" onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
			{children}
			{visible && (
				<div className="absolute bottom-full left-1/2 z-10 mb-2 w-max -translate-x-1/2 transform rounded bg-gray-800 p-2 text-sm text-white shadow-lg">
					{message}
				</div>
			)}
		</div>
	);
}

export default Tooltip;
