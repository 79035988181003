import dayjs from "dayjs";
import DOMPurify from "dompurify";
import React from "react";

import { sections } from "./enums";

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const maxPercentageRewardsPointsToPay = 0.615;
export const packageNames = {
	silver: {
		originalAmount: 1950,
		maxRedeemablePoints: 1200,
		packageDetail: "Dproz Silver package. 14 days full access",
	},
	gold: { originalAmount: 5850, maxRedeemablePoints: 3600, packageDetail: "Dproz Gold package. 60 days full access" },
	platinum: {
		originalAmount: 51500,
		maxRedeemablePoints: 31650,
		packageDetail: "Dproz Platinum package. 12 months full access",
	},
};

export function convertDateToWords(date) {
	const dateObj = new Date(date);
	const month = months[dateObj.getMonth()];
	const year = dateObj.getFullYear();
	return `${month} ${year}`;
}

export function formatAPIDate(format, date) {
	const dateObj = new Date(date);
	const year = dateObj.getFullYear();
	const month = months[dateObj.getMonth()];
	const hour = dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours();
	const min = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
	const day = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate();

	return format === "M-D-Y-T" ? `${month} ${day}, ${year} ${hour}:${min}` : `${month} ${day}, ${year}`;
}

export function convertParagraphsToList(text) {
	return text.replace("<p>", "<li>").replace("</p>", "</li>");
}

export function dateToMYObject(date) {
	const dateObj = new Date(date);
	return {
		month: dateObj.getMonth() + 1,
		year: dateObj.getFullYear(),
	};
}

export function currencyFormat(amount) {
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function dateToMYDObject(date) {
	const dateObj = new Date(date);
	return {
		month: dateObj.getMonth() + 1,
		year: dateObj.getFullYear(),
		day: dateObj.getDate(),
	};
}

export function dateObjToYMD(obj) {
	return dayjs(`${obj.year}-${obj.month}-${obj.day}`).format("MMM DD, YYYY");
}

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export function dateToMY(date) {
	return dayjs(date).format("MMM YYYY");
}

export function dateToDMY(date) {
	const dateObj = new Date(date);
	return `${monthNames[dateObj.getMonth()]} ${dateObj.getDate()} , ${dateObj.getFullYear()}`;
}

export function convertUlToArrayDefault(text) {
	return text
		.replaceAll("</li>", "")
		.replaceAll("<ul>", "")
		.replaceAll("<p><br></p>", "")
		.replaceAll("<br>", "")
		.replaceAll("</ul>", "")
		.split("<li>");
}

export function convertUlToArray(inputs) {
	const text = inputs
		.toString()
		.replaceAll("</li>", "")
		.replaceAll("<ul>", "")
		.replaceAll("</ul>", "")
		.replaceAll("<p>", "")
		.replaceAll("</p>", "")
		.replaceAll("<br>", "")
		.replaceAll("<p><br></p>", "")
		.split("<li>");

	let itemData = text.reduce((prev, value) => {
		return [
			...prev,
			{
				bp: true,
				value: value.trim(),
			},
		];
	});

	return (itemData = Array.isArray(itemData) ? itemData : [{ bp: false, value: itemData }]);
}

export function convertArrayToUl(array) {
	let list = "";

	array.forEach((element) => {
		if (element.bp) {
			list = list.concat("<ul><li>", element.value, "</li></ul>");
		} else {
			list = list.concat("<p>", element.value, "</p>");
		}
	});

	return list;
}

export function convertDateTo2Digits(date) {
	return date < 10 ? `0${date}` : date;
}

export function logToConsole(data) {
	// eslint-disable-next-line no-console
	return console.log(data);
}

export function searchJobFunction(query, jobFunction) {
	// search job function array for query or something similar to it
	return jobFunction
		.some((item) => {
			return item.toLowerCase().includes(query.toLowerCase());
		})
		.toString();
}

export function formatAmount(amount) {
	return amount.toLocaleString("en-US");
}

export function getSanitizedHtml(dirty) {
	return React.createElement("span", {
		dangerouslySetInnerHTML: {
			__html: DOMPurify.sanitize(dirty, {
				ADD_ATTR: ["target"],
				ALLOWED_TAGS: ["b", "i", "a", "br", "ul", "li", "span"],
			}),
		},
	});
}

export function calculateDateToToday(date) {
	// To set two dates to two variables
	const createdDate = new Date(date);
	const todayDate = new Date();

	// To calculate the time difference of two dates
	const differenceInTime = todayDate.getTime() - createdDate.getTime();

	// To calculate the no. of days between two dates
	const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

	if (differenceInDays <= 0) {
		return "Today";
	} else if (differenceInDays === 1) {
		return "Yesterday";
	} else {
		return `${differenceInDays} days ago`;
	}
}

export function calculateDayRemainsToDate(obj) {
	// To set two dates to two variables
	const createdDate = new Date(obj.year, obj.month - 1, obj.day);
	const todayDate = new Date();

	// To calculate the time difference of two dates
	const differenceInTime = createdDate.getTime() - todayDate.getTime();

	// To calculate the no. of days between two dates
	const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

	if (differenceInDays <= 0) {
		return "Today";
	} else if (differenceInDays === 1) {
		return "Tomorrow";
	} else {
		return `${differenceInDays} days remains`;
	}
}

export function getTemplateName(templateId) {
	const temId = templateId;
	//if contains q substring from q_to_end
	const indexOfq = temId.indexOf("q");
	let intermediateName = templateId;

	if (indexOfq >= 0) {
		intermediateName = intermediateName.substring(indexOfq + 1);
	}

	//replace x with _
	intermediateName = intermediateName.replaceAll("x", "_");

	return intermediateName;
}

export function match(expression, cases) {
	return cases[expression] || cases?.default;
}

export const getColumnName = (columns, columnName) => {
	const column = columns.find((column) => column === columnName);
	return column || "";
};

export function capitalizeFirstLetterOfWords(input) {
	if (!input) return input;
	const words = input.split(" ");

	const capitalizedWords = words.map((word) => {
		if (word.length === 0) {
			return "";
		}
		const firstLetter = word[0].toUpperCase();
		const restOfWord = word.slice(1).toLowerCase();
		return firstLetter + restOfWord;
	});

	return capitalizedWords.join(" ");
}

export function getRewardPointTextColor(status) {
	if (status === "PENDING") {
		return "text-warning";
	} else if (status === "CLAIMED") {
		return "text-success";
	} else {
		return "text-muted";
	}
}

export function calculateMaximumRedeemablePoints(packageName, rewards) {
	const dprozPackage = packageNames[packageName];
	const maxRedeemablePoints = dprozPackage["maxRedeemablePoints"];
	const originalAmount = dprozPackage["originalAmount"];
	const points = rewards.reduce((total = 0, rewardPoint) => total + rewardPoint.rewardPoints, 0);
	const amountPaidByPoints = points > maxRedeemablePoints ? maxRedeemablePoints : points;
	let reducedAmountPaidByPoints = amountPaidByPoints;
	let redeemableRewardIds = [];
	const oneFiftyRewardPoint = 150;
	const fiftyRewardPoint = 50;
	const oneFiftyRewardPointItems = rewards.filter((item) => item.rewardPoints == oneFiftyRewardPoint);
	const fiftyRewardPointItems = rewards.filter((item) => item.rewardPoints == fiftyRewardPoint);
	const selectedRewardItems = [];

	if (oneFiftyRewardPointItems.length !== 0 || fiftyRewardPointItems.length !== 0) {
		if (amountPaidByPoints > fiftyRewardPoint && oneFiftyRewardPointItems.length > 0) {
			for (let index = 0; index < oneFiftyRewardPointItems.length; index++) {
				const rewardItem = oneFiftyRewardPointItems[index];
				if (reducedAmountPaidByPoints > 0 && rewardItem.rewardPoints > 0) {
					if (rewardItem.rewardPoints > reducedAmountPaidByPoints) break;
					selectedRewardItems.push(rewardItem);
					reducedAmountPaidByPoints = reducedAmountPaidByPoints - rewardItem.rewardPoints;
				}
			}
		}

		if (reducedAmountPaidByPoints > 0 && fiftyRewardPointItems.length > 0) {
			for (let index = 0; index < fiftyRewardPointItems.length; index++) {
				const rewardItem = fiftyRewardPointItems[index];
				if (reducedAmountPaidByPoints > 0 && rewardItem.rewardPoints > 0) {
					if (rewardItem.rewardPoints > reducedAmountPaidByPoints) break;
					selectedRewardItems.push(rewardItem);
					reducedAmountPaidByPoints = reducedAmountPaidByPoints - rewardItem.rewardPoints;
				}
			}
		}
	}
	redeemableRewardIds = selectedRewardItems.map((item) => item._id);

	return {
		amountPaidByPoints: amountPaidByPoints,
		amount: originalAmount - amountPaidByPoints,
		originalAmount: originalAmount,
		rewards: rewards,
		totalAvailablePoints: points,
		redeemableRewardIds: redeemableRewardIds,
	};
}

export function urlify(text) {
	var urlRegex = /(https?:\/\/[^\s]+)/g;
	return text.replace(urlRegex, function (url) {
		return `
		<br/>
		<div class="d-flex align-items-center justify-content-center">
		  <a class="btn btn-primary text-center" href="${url}" target="_blank" rel="nofollow noreferrer noopener">Click to Apply</a>
		</div>
		`;
	});
}

export function removeHTTPAndDomainName(item) {
	if (item.type === "linkedIn") {
		return item?.value?.replace("https://", "")?.replace("www.", "")?.replace("linkedin.com/in/", "");
	} else if (item.type === "twitter") {
		return item?.value?.replace("https://", "")?.replace("twitter.com/", "");
	} else if (item.type === "github") {
		return item?.value?.replace("https://", "")?.replace("github.com/", "");
	} else {
		return item?.value?.replace("https://", "")?.replace("www.", "");
	}
}

export function getFirstWordSeparedByFullColon(text) {
	const splitArray = text.split(":");
	let wordBeforeColon = splitArray[0].trim();
	return wordBeforeColon;
}

export function convertSplittedSkillsToNormalSkillsArray(skillsArray) {
	return skillsArray.map((skill) => ({
		bp: true,
		value: `${skill.title}: ${skill.skills
			.filter((item) => item.added)
			.map((item) => item.skill)
			.join(", ")}`,
	}));
}

export function getNestedProperty(obj, path) {
	return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : undefined), obj);
}

export function getSectionName(obj, sectionName) {
	if (sectionName === sections.SUMMARY) {
		return getNestedProperty(obj, "summary");
	}
	if (sectionName === sections.SKILLS) {
		return getNestedProperty(obj, "skills");
	}
	if (sectionName === sections.CERTIFICATION) {
		return getNestedProperty(obj, "extras.certifications");
	}
	if (sectionName === sections.ACCOMPLISHMENTS) {
		return getNestedProperty(obj, "extras.accomplishments");
	}
	if (sectionName === sections.AFFILIATION) {
		return getNestedProperty(obj, "extras.affiliations");
	}
	if (sectionName === sections.ADDITIONAL_INFO) {
		return getNestedProperty(obj, "extras.additionalInfo");
	}
	if (sectionName === sections.CUSTOM) {
		return getNestedProperty(obj, "extras.customExtras.data");
	}
	return getNestedProperty(obj, "summary");
}

export function getResumeSectionPositionFromTop(element) {
	if (!element) return 0;
	const rect = element.getBoundingClientRect();
	const scrollTop = window.scrollY;
	const elementTopPosition = rect.top + scrollTop - 80;
	return elementTopPosition;
}

export function isNotEmptyCustomReasonsOrRecommendations(reasonsOrRecommendations) {
	if (convertUlToArray(reasonsOrRecommendations).length === 0) return false;
	return convertUlToArray(reasonsOrRecommendations)[0].value.length > 0;
}

export function isNotEmptyValue(value) {
	return value.length > 0;
}

export function getInitials(name = "") {
	return name
		.replace(/\s+/, " ")
		.split(" ")
		.slice(0, 2)
		.map((v) => v && v[0].toUpperCase())
		.join("");
}

export function checkObjectsHaveSameKeysAndValues(obj1, obj2) {
	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (let key of keys1) {
		if (obj1[key] !== obj2[key]) {
			return false;
		}
	}

	return true;
}

export function removeTextAfterAt(inputString) {
	const atIndex = inputString.indexOf("at");
	if (atIndex !== -1) {
		return inputString.substring(0, atIndex).trim();
	}
	return inputString;
}

export function convertArrayStringToArray(arrayString) {
	let arrayData = [];
	try {
		const parsedData = JSON.parse(arrayString);
		if (Array.isArray(parsedData)) {
			arrayData = parsedData.map((item) => {
				return { bp: true, value: item };
			});
		}
	} catch (error) {
		arrayData.push({ bp: true, value: arrayString });
	}
	return arrayData;
}

export function getLinkFromString(text) {
	const urlPattern = /(https?:\/\/[^\s]+)/;
	const match = text.match(urlPattern);
	return match ? match[0] : "";
}
