import React, { useEffect, useMemo, useRef, useState } from "react";

import { cn } from "../../handlers/cn.utils";
import Dropdown from "../Dropdown/Dropdown";
import DropdownOptions from "../Dropdown/DropdownOptions";
import SelectSearch from "../SelectSearch";
import { SEARCH_CATEGORIES } from "./Table";

function EditableFilter({ filter, removeFilter, index, setFilters, columns, data }) {
	const [filterValue, setFilterValue] = useState(filter.value || "");

	useEffect(() => {
		if (filterValue) {
			if (filterValue.trim() !== "") {
				setFilters((prev) => {
					const newFilters = [...prev];
					newFilters[index].value = filterValue;
					return newFilters;
				});
			}
		} else {
			setFilters((prev) => {
				const newFilters = [...prev];
				newFilters[index].value = "";
				return newFilters;
			});
		}
	}, [filterValue, index, setFilters]);

	const inputRef = useRef(null);

	const [showInput, setShowInput] = useState(false);

	useEffect(() => {
		if (filter.filter === SEARCH_CATEGORIES.CONTAINS || filter.filter === SEARCH_CATEGORIES.DOESNT_CONTAIN) {
			setShowInput(true);
		} else {
			setShowInput(false);
		}
	}, [filter]);

	const [dismissDropdown, setDismissDropdown] = useState(Math.random());

	const searchCategories = [
		SEARCH_CATEGORIES.EMPTY,
		SEARCH_CATEGORIES.NOT_EMPTY,
		SEARCH_CATEGORIES.CONTAINS,
		SEARCH_CATEGORIES.DOESNT_CONTAIN,
	].map((item, index) => ({
		key: index,
		label: item,
	}));

	function Filter() {
		return (
			<Dropdown label={filter.filter !== "" ? filter.filter.toLowerCase() : "Filter"} dismiss={dismissDropdown}>
				<DropdownOptions
					data={searchCategories}
					onSelect={({ label: value }) => {
						if (value === SEARCH_CATEGORIES.EMPTY || value === SEARCH_CATEGORIES.NOT_EMPTY) {
							const appliedFilters = {
								column: filter.column,
								filter: value === SEARCH_CATEGORIES.EMPTY ? SEARCH_CATEGORIES.EMPTY : SEARCH_CATEGORIES.NOT_EMPTY,
								value: "",
							};

							setFilters((prev) => {
								const newFilters = [...prev];
								newFilters[index] = appliedFilters;
								return newFilters;
							});

							setDismissDropdown(Math.random());
						} else {
							const appliedFilters = {
								column: filter.column,
								filter:
									value === SEARCH_CATEGORIES.CONTAINS ? SEARCH_CATEGORIES.CONTAINS : SEARCH_CATEGORIES.DOESNT_CONTAIN,
								value: "",
							};

							setFilters((prev) => {
								const newFilters = [...prev];
								newFilters[index] = appliedFilters;

								return newFilters;
							});

							setShowInput(true);
							setDismissDropdown(Math.random());
						}
					}}
				/>
			</Dropdown>
		);
	}

	const columnType = columns.find((item) => item.key === filter.column)?.type;

	const inputType = useMemo(() => {
		if (columnType === "date") {
			return "date";
		} else if (columnType === "boolean") {
			return "checkbox";
		} else {
			return "text";
		}
	}, [columnType]);

	const dropdownValues = useMemo(() => {
		if (columnType === "group") {
			const column = filter.column;

			const uniqueValues = [...new Set(data.map((item) => item[column]))];

			return uniqueValues;
		}
		return [];
	}, [columnType, data, filter.column]);

	if (showInput) {
		return (
			<div className="flex items-center gap-2">
				<div className="group relative h-fit rounded border border-gray-200 px-2 py-1">
					<label
						className={cn(
							"absolute top-1.5 z-10 block w-max px-1 text-sm text-gray-500 transition-all group-focus-within:relative group-focus-within:top-0 group-focus-within:mb-1 group-focus-within:text-primary",
							{
								"relative !top-0 mb-2": filterValue !== "" || filter.value !== "" || columnType === "group",
							},
						)}
					>
						<div className="flex items-center space-x-2">
							<span className="px-0 capitalize">{filter.column}</span>{" "}
							<button className="rounded-sm bg-gray-100 font-semibold h-4">
								<Filter />
							</button>
						</div>
					</label>
					{columnType !== "group" ? (
						<input
							type={inputType}
							className="block rounded border-0 bg-transparent p-1 text-sm leading-none placeholder:text-base placeholder:text-gray-300 hover:border-gray-300 focus:border focus:border-gray-300 focus:bg-transparent focus:ring-0 disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 disabled:placeholder:text-gray-400 group-focus-within:border group-focus-within:border-gray-300"
							ref={inputRef}
							value={filterValue}
							onChange={(e) => setFilterValue(e.target.value)}
						/>
					) : (
						<SelectSearch
							data={dropdownValues}
							onSelect={(item) => {
								const appliedFilter = {
									...filter,
									value: item,
								};

								setFilters((prev) => {
									const newFilters = [...prev];
									newFilters[index] = appliedFilter;
									return newFilters;
								});
							}}
						/>
					)}
				</div>
				<span
					onClick={() => removeFilter(index)}
					className="flex aspect-[1/1] h-5 cursor-pointer items-center justify-center rounded-full border border-gray-300"
				>
					<i className="bi bi-x text-lg leading-none text-error-default" />
				</span>
			</div>
		);
	}

	return (
		<>
			<span className="h-fit rounded border border-gray-100 p-1 px-2 py-1 text-sm">
				<span className="capitalize">{filter.column}</span>{" "}
				<span className="font-medium text-primary">
					<button className="rounded-sm bg-gray-100 px-1 font-semibold">
						<Filter />
					</button>{" "}
				</span>{" "}
				{filter?.value}
				<i className="bi bi-x ml-1 text-lg leading-none text-error-default" onClick={() => removeFilter(index)} />
			</span>
		</>
	);
}

export default EditableFilter;
