import React, { useEffect, useState } from "react";

import Tooltip from "../Tooltip/Tooltip";

function EditableTD({ value, toolTipMessage, onChange, style }) {
	const [editing, setEditing] = useState(false);
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const handleBlur = () => {
		setEditing(false);
		onChange(inputValue);
	};

	return (
		<td onClick={() => setEditing(true)} style={style && style}>
			{editing ? (
				<input
					type="text"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					onBlur={handleBlur}
					onKeyDown={(e) => e.key === "Enter" && handleBlur()}
					autoFocus
					className="box-border w-full rounded-md border-0 border-gray-200 p-0 text-sm font-medium leading-none text-gray-900 focus:border-gray-400 focus:outline-none focus:ring-0"
				/>
			) : toolTipMessage ? (
				<Tooltip message={toolTipMessage}>{value}</Tooltip>
			) : (
				value
			)}
		</td>
	);
}

export default EditableTD;
