import React from "react";

function Modal({ isOpen, onClose, children, title = "" }) {
	if (!isOpen) return null;

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-950 bg-opacity-50">
			<div className="w-full overflow-hidden rounded-lg bg-white shadow-lg" style={{ maxWidth: 1200 }}>
				<div className="flex items-center justify-between bg-gray-50 p-3">
					<h2 className="text-lg font-semibold">{title}</h2>
					<button onClick={onClose} className="text-gray-500">
						<i className="bi bi-x-lg text-gray-900" />
					</button>
				</div>
				<div className="p-3">{children}</div>
			</div>
		</div>
	);
}

export default Modal;
