import React from "react";

import ExpertResumesLayout from "../../components/ExpertResumesLayout/ExpertResumesLayout";
import { getJobsRoutes } from "../../handlers/navigationItems";
import LeadsList from "./LeadsList";

function SharedLeads() {
	return (
		<ExpertResumesLayout navItems={getJobsRoutes()} headingTitle="Shared Leads" headingIcon="arrow-left">
			<div
				className="container px-5"
				style={{
					margin: 0,
					maxWidth: "100vw",
				}}
			>
				<div className="row">
					<div className="col left container">
						<div className="d-flex justify-content-between mb-4 align-items-center">
							<h3 className="mt-4">Shared Leads</h3>
						</div>

						<LeadsList />
					</div>
				</div>
			</div>
		</ExpertResumesLayout>
	);
}

export default SharedLeads;
