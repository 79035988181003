import React, { useEffect, useRef, useState } from "react";

import { cn } from "../../handlers/cn.utils";

function SelectSearch({ data, onSelect }) {
	const [items, setItems] = useState(data);

	const [searchValue, setSearchValue] = useState("");
	const handleSearch = (e) => {
		setSearchValue(e.target.value);
		const filteredItems = data.filter((item) => item.toLowerCase().includes(e.target.value.toLowerCase()));
		setItems(filteredItems);
	};

	const [showDropdown, setShowDropdown] = useState(false);
	const inputRef = useRef(null);

	useEffect(() => {
		if (searchValue === "") {
			onSelect("");
		}
	}, [searchValue]);

	return (
		<div className="relative">
			<input
				type="text"
				placeholder="Search"
				className={cn(
					"block rounded border-0 bg-transparent p-1 text-sm leading-none placeholder:text-base placeholder:text-gray-300 hover:border-gray-300 focus:border focus:border-gray-300 focus:bg-transparent focus:ring-0 disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-400 disabled:placeholder:text-gray-400 group-focus-within:border group-focus-within:border-gray-300",
					{
						"w-72": showDropdown,
					},
				)}
				value={searchValue}
				onFocus={() => setShowDropdown(true)}
				onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
				onChange={handleSearch}
				ref={inputRef}
			/>
			{showDropdown && (
				<div className="absolute max-h-[400px] w-full min-w-fit max-w-full overflow-scroll overflow-x-hidden rounded rounded-t-none border border-gray-100 bg-white shadow">
					{items.map((item, index) => (
						<div
							key={index}
							className="flex cursor-pointer items-center rounded px-2 py-1 text-sm hover:bg-background"
							onClick={() => {
								onSelect(item);
								setShowDropdown(false);
								setSearchValue(item);
							}}
						>
							<p>{item}</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default SelectSearch;
