import React, { useEffect, useRef, useState } from "react";

import { cn } from "../../handlers/cn.utils";

function Dropdown({ label, children, dismiss }) {
	const [showContent, setShowContent] = useState(false);

	const containerRef = useRef(null);

	const handleClickOutside = (e) => {
		if (containerRef.current && !containerRef.current.contains(e.target)) {
			setShowContent(false);
		}
	};

	const handleEscape = (e) => {
		if (e.key === "Escape") {
			setShowContent(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handleEscape);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handleEscape);
		};
	}, []);

	useEffect(() => {
		if (dismiss) {
			setShowContent(false);
		}
	}, [dismiss]);

	const dropdownRef = useRef(null);

	const [{ r0, l0 }, setRL0] = useState({ r0: false, l0: false });

	useEffect(() => {
		if (showContent) {
			const r0 = dropdownRef.current.getBoundingClientRect().right > window.innerWidth;
			const l0 =
				!r0 && containerRef.current.getBoundingClientRect().left > dropdownRef.current.getBoundingClientRect().left;
			setRL0({ r0, l0 });
		} else {
			setRL0({ r0: false, l0: false });
		}
	}, [showContent]);

	return (
		<div ref={containerRef} className="relative">
			<button onClick={() => setShowContent(!showContent)} className="rounded px-1">
				{label}
			</button>
			<div
				className={cn("absolute mt-2 hidden w-56 rounded border border-gray-100 bg-white p-2 shadow", {
					block: showContent,
					"left-auto right-0": r0,
					"left-0 right-auto": l0,
				})}
				ref={dropdownRef}
			>
				{children}
			</div>
		</div>
	);
}

export default Dropdown;
